<template>
  <div>
    <div class="p-1">
      <sub-header :backButton="true"></sub-header>
    </div>
    <div class="p-1">
      <div class="shadow-md relative rounded-2xl bg-white flex gap-6 flex-col">
        <div
          class="p-3 panel-head bg-primary-300 font-bold text-base-content flex gap-3 justify-between items-center rounded-t-2xl"
        >
          Edit Currency
        </div>
        <div class="py-24 flex items-center justify-center" v-if="isLoading">
          <Loader />
        </div>
        <div class="py-3 px-6" v-else>
          <FormulateForm #default="{ hasErrors }">
            <div class="grid grid-cols-3 gap-x-6">
              <div class="h-6">
                <h2 class="mb-1 mt-1">Currency Code</h2>
                <Dropdown
                  placeholder="currency codes"
                  :options="currencyCodes"
                  searchable
                  :colors="{
                    text: '#282828',
                    bg: 'white',
                    svg: '#282828',
                    border: '#bcbcbc',
                  }"
                  class="disabledInputs"
                  :value="currencyCode"
                  @input="(value) => updateCode(value)"
                  :config="{ label: 'currency_code', trackBy: 'currency_code' }"
                  maxWidth="100%"
                  minWidth="100%"
                  minHeight="20%"
                  :loading="loadingCurrency"
                  disabled
                />
              </div>
              <FormulateInput
                class="w-full"
                label="Currency Symbol"
                placeholder="currency symbol"
                type="text"
                v-model="currencyInformation.currency_symbol"
                disabled
                :element-class="
                  (context, classes) => ['flex-1 min-w-full disabledInputs'].concat(classes)
                "
              />
              <FormulateInput
                class="w-full"
                label="Currency Name"
                placeholder="currency name"
                v-model="currencyInformation.currency_name"
                type="text"
                disabled
                :element-class="
                  (context, classes) => ['flex-1 min-w-full disabledInputs'].concat(classes)
                "
              />
              <div class="h-6">
                <h2 class="mb-1 mt-1">Decimal Places</h2>
                <Dropdown
                  placeholder="decimal places"
                  :options="decimalPlaces"
                  searchable
                  :colors="{
                    text: '#282828',
                    bg: 'white',
                    svg: '#282828',
                    border: '#bcbcbc',
                  }"
                  :value="decimalValue"
                  @input="(value) => updateDecimalValue(value)"
                  :config="{ label: 'decimal', trackBy: 'decimal' }"
                  maxWidth="100%"
                  minWidth="100%"
                  minHeight="20%"
                  :disabled="baseCurrency"
                />
              </div>
              <div class="h-6">
                <h2 class="mb-1 mt-1">Format</h2>
                <Dropdown
                  placeholder="format"
                  :options="formatOptions"
                  searchable
                  :colors="{
                    text: '#282828',
                    bg: 'white',
                    svg: '#282828',
                    border: '#bcbcbc',
                  }"
                  :value="formatValue"
                  @input="(value) => updateFormatValue(value)"
                  :config="{ label: 'format', trackBy: 'format' }"
                  maxWidth="100%"
                  minWidth="100%"
                  minHeight="20%"
                  :disabled="baseCurrency"
                />
              </div>
              <div class="flex gap-4">
                <input
                  type="checkbox"
                  class="checkbox mt-9 checkbox-sm"
                  v-model="masterCurrency"
                  @change="handleMasterCurrencyChange"
                  :disabled="baseCurrency"
                />
                <label class="cursor-pointer label">
                  <span class="label-text mt-8">Master Currency</span>
                </label>
              </div>

              <div class="mt-4">
                <h2>Actions</h2>
                <div class="flex gap-7">
                  <div class="flex gap-2">
                    <input
                      type="checkbox"
                      class="checkbox mt-1 checkbox-sm"
                      value="1"
                      v-model="status.activated"
                      :disabled="disableCheckBox  || baseCurrency"
                      @change="handleStatusClick(true)"
                    />
                    <label class="cursor-pointer label">
                      <span class="label-text">Activate</span>
                    </label>
                  </div>

                  <div class="flex gap-2">
                    <input
                      type="checkbox"
                      class="checkbox mt-1 checkbox-sm"
                      value="0"
                      v-model="status.deactivated"
                      :disabled="disableCheckBox || baseCurrency"
                      @change="handleStatusClick(false)"
                    />
                    <label class="cursor-pointer label">
                      <span class="label-text">Deactivate</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-end max-w-full">
              <Button
                @click="updateCurrency(hasErrors)"
                class="btn-primary float-right mt-4"
                text="Update"
                :disabled="baseCurrency || !changeMade"
              />
            </div>
          </FormulateForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubHeader from "@/components/SubHeader";
import Button from "@/components/button";
import Dropdown from "@shared/components/dropdown-base";
import { getCurrencyDetails, getCurrencyCodes, editCurrency } from "./services";
import Loader from "@shared/loader";

export default {
  name: "createCurrency",
  data: function () {
    return {
      changeMade: false,
      currencyCodes: [],
      masterCurrency: false,
      currencyCode: null,
      loadingCurrency: false,
      currencyInformation: {
        currency_symbol: null,
        currency_name: null,
      },
      decimalPlaces: [
        { decimal: 1 },
        { decimal: 2 },
        { decimal: 3 },
        { decimal: 4 },
      ],
      decimalValue: null,
      isLoading: true,
      status: {
        activated: false,
        deactivated: false,
      },
      disableCheckBox: false,
      formatValue: null,
      formatOptions: [
        { format: "123,456,789.01" },
        { format: "12,34,56,789.01" },
        { format: "123456,789.01" },
        { format: "123456789.01" },
      ],
      baseCurrency: false
    };
  },
  components: {
    SubHeader,
    Button,
    Dropdown,
    Loader,
  },
  async mounted() {
    await this.setCurrencyData();
    this.isLoading = false;
  },
  watch:{
    status(){
      if( !this.isLoading && !this.loadingCurrency){
        this.changeMade = true
      }
    },
    masterCurrency(){
      if( !this.isLoading && !this.loadingCurrency){
        this.changeMade = true
      }
    },
    decimalValue(){
      if( !this.isLoading && !this.loadingCurrency){
        this.changeMade = true
      }
    },
    formatValue(){
      if( !this.isLoading && !this.loadingCurrency){
        this.changeMade = true
      }
    },
  },
  methods: {
    async setCurrencyData() {
      const { data } = await getCurrencyDetails(this.$route.params.id);
      this.masterCurrency = data.master_currency;
      this.disableCheckBox = this.masterCurrency ? true : false;
      this.currencyInformation = data;
      if (this.currencyInformation.base_currency) {
          this.baseCurrency = true
      }
      if (this.currencyInformation.active) {
          this.status.activated = true
      }
      else {
          this.status.deactivated = true
      }
      this.decimalValue = this.currencyInformation.decimal_place;
      this.formatValue = this.currencyInformation.format;
      const result = await getCurrencyCodes();
      this.currencyCodes = result.data.data;
      this.currencyCode = result.data.data.filter(
        (res) => res.id == this.currencyInformation.currency_id
      );
      this.currencyInformation["currency_id"] = this.currencyCode[0].id
    },
    updateCode(value) {
      this.currencyInformation = value;
      this.currencyInformation["currency_id"] = value.id
    },
    updateDecimalValue(value) {
      this.decimalValue = value.decimal
    },
    handleStatusClick(active) {
      if (active) {
        this.status.deactivated = false;
      } else {
        this.status.activated = false;
      }
    },
    handleMasterCurrencyChange() {
      if (!this.masterCurrency) {
        this.disableCheckBox = false;
      } else {
        this.status.deactivated = false;
        this.status.activated = true;
        this.disableCheckBox = true;
      }
    },
    updateFormatValue(value) {
        this.formatValue = value.format
    },
    async updateCurrency() {
      try {
        if (!this.status.activated && !this.status.deactivated) {
          this.$toast.error("Please select action");
          return;
        }
        let payload = {
          id: this.$route.params.id,
          currency_id: this.currencyInformation.currency_id,
          base_currency: false,
          master_currency: this.masterCurrency,
          format: this.formatValue,
          decimal_place: this.decimalValue,
          active: this.status.activated ? true : false
        };
        await editCurrency(payload)
        this.$toast.success("Currency updated successfully")
        this.$router.back()
      } catch (error) {
        this.$toast.error(error.response.data.detail)
      }
    },
  },
};
</script>
<style>

.formulate-input[data-classification="text"] input{
  border-radius: 0.5rem;
}
.disabledInputs {
  background-color: hsla(var(--b2) / var(--tw-bg-opacity, 1));
  cursor: not-allowed !important;
}
.disabledInputs input {
  cursor: not-allowed !important;
}
</style>